/* eslint-disable max-params */
import interceptor from './interceptor';
import { apiUrl } from '../environment/index';

export const leadPartnersGetAllReferrals = (
  page = 1,
  startDate,
  endDate,
  keyword = '',
  limit = 25
  // eslint-disable-next-line max-params
) => {
  return interceptor.get(`${apiUrl}/lead_partners/referral`, {
    params: {
      page,
      startDate,
      endDate,
      keyword,
      limit,
    },
  });
};

export const leadPartnerGetResource = () => {
  return interceptor.get(`${apiUrl}/lead_partners/resources`);
};

export const getLeadPartnerResourceItemStatistics = (
  itemId,
  startDate,
  endDate
) => {
  return interceptor.get(
    `${apiUrl}/lead_partners/statistics/resource-items?itemId=${itemId}&startDate=${startDate}&endDate=${endDate}`
  );
};

export const getLeadPartnerResourceItemEmailStatistics = (
  startDate,
  endDate,
  itemId = ''
) => {
  return interceptor.get(
    `${apiUrl}/lead_partners/statistics/resource-item-email-clicks?startDate=${startDate}&endDate=${endDate}&itemId=${itemId}`
  );
};

export const getLeadPartnerResourceItemPhoneStatistics = (
  startDate,
  endDate,
  itemId = ''
) => {
  return interceptor.get(
    `${apiUrl}/lead_partners/statistics/resource-item-phone-clicks?startDate=${startDate}&endDate=${endDate}&itemId=${itemId}`
  );
};

export const getLeadPartnerBenefitViewedStatistics = (
  startDate,
  endDate,
  itemId = ''
) => {
  return interceptor.get(
    `${apiUrl}/lead_partners/statistics/benefits-viewed?startDate=${startDate}&endDate=${endDate}&itemId=${itemId}`
  );
};

export const getLeadPartnerBenefitOutboundClicksStatistics = (
  startDate,
  endDate,
  itemId = ''
) => {
  return interceptor.get(
    `${apiUrl}/lead_partners/statistics/benefit-outbound-clicks?startDate=${startDate}&endDate=${endDate}&itemId=${itemId}`
  );
};

export const getLeadPartnerBenefitEmailStatistics = (
  startDate,
  endDate,
  itemId = ''
) => {
  return interceptor.get(
    `${apiUrl}/lead_partners/statistics/email-clicks?startDate=${startDate}&endDate=${endDate}&itemId=${itemId}`
  );
};

export const getLeadPartnerBenefitPhoneStatistics = (
  startDate,
  endDate,
  itemId = ''
) => {
  return interceptor.get(
    `${apiUrl}/lead_partners/statistics/phone-clicks?startDate=${startDate}&endDate=${endDate}&itemId=${itemId}`
  );
};

export const leadPartnerExportReferrals = (
  startDate = '',
  endDate = '',
  filterValues = [],
  searchKeywords
) => {
  return interceptor.get(`${apiUrl}/lead_partners/export/referrals`, {
    params: {
      startDate,
      endDate,
      filterValues: filterValues.join(','),
      searchKeywords,
    },
    responseType: 'blob',
    timeout: 30000,
  });
};
